import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

export default <Array<RouteRecordRaw>>[
  {
    name: names.administration.organization.users,
    path: paths.administration.organization.users,
    meta: {
      permissions: permissions.administration.organization.users,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-org' */
      './index.vue'
    ),
    beforeEnter(to, from, next) {
      if (!to.params?.orgId) {
        next(from || { name: names.administration.root });
        return;
      }
      next();
    },
    children: [
      {
        name: names.administration.organization.createUser,
        path: paths.administration.organization.createUser,
        meta: {
          permissions: permissions.administration.organization.createUser,
          isAuthed: false,
          requiresAuth: true,
        },
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: 'administration-org' */
          './NewUser.vue'
        ),
        beforeEnter(to, from, next) {
          if (!to.params?.orgId) {
            next(from || { name: names.administration.root });
            return;
          }
          next();
        },
      },
    ],
  },
];
