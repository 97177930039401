import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';
import user from './User/routes';

const users: Array<RouteRecordRaw> = [
  {
    name: names.administration.users,
    path: paths.administration.users,
    meta: {
      permissions: permissions.administration.users,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-users' */
      './index.vue'
    ),
    children: [
      {
        name: names.administration.createUser,
        path: paths.administration.createUser,
        meta: {
          permissions: permissions.administration.createUser,
          isAuthed: false,
          requiresAuth: true,
        },
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: 'administration-users' */
          './NewUser.vue'
        ),
      },
    ],
  },
  ...user,
];

export default users;
