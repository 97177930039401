<template>
  <svg v-bind="attrs">
    <path
      d="M23.8247 31.5994H6.17487C4.31366 31.5994 2.7998 30.1111 2.7998 28.2812V3.71532C2.80215 1.88777 4.31366 0.399414 6.17487 0.399414H23.8247C25.686 0.399414 27.1998 1.88777 27.1998 3.71532V28.2812C27.1998 30.1111 25.686 31.5994 23.8247 31.5994ZM6.17487 2.01468C5.21962 2.01468 4.44274 2.77847 4.44274 3.71532V28.2812C4.44274 29.2204 5.21962 29.9841 6.17487 29.9841H23.8247C24.78 29.9841 25.5569 29.2204 25.5569 28.2812V3.71532C25.5569 2.77616 24.78 2.01468 23.8247 2.01468H6.17487Z"
      fill="white"
    />
    <path
      d="M9.99941 0.799805H8.39941V31.1998H9.99941V0.799805Z"
      fill="white"
    />
    <path
      d="M23.1713 11.599H12.0291C11.572 11.599 11.2002 11.2402 11.2002 10.799C11.2002 10.3579 11.572 9.99902 12.0291 9.99902H23.1713C23.6284 9.99902 24.0002 10.3579 24.0002 10.799C24.0002 11.2402 23.6284 11.599 23.1713 11.599Z"
      fill="white"
    />
    <path
      d="M23.1713 15.1986H12.0291C11.572 15.1986 11.2002 14.8398 11.2002 14.3986C11.2002 13.9575 11.572 13.5986 12.0291 13.5986H23.1713C23.6284 13.5986 24.0002 13.9575 24.0002 14.3986C24.0002 14.8398 23.6284 15.1986 23.1713 15.1986Z"
      fill="white"
    />
    <path
      d="M23.1713 18.7992H12.0291C11.572 18.7992 11.2002 18.4404 11.2002 17.9992C11.2002 17.5581 11.572 17.1992 12.0291 17.1992H23.1713C23.6284 17.1992 24.0002 17.5581 24.0002 17.9992C24.0002 18.4404 23.6284 18.7992 23.1713 18.7992Z"
      fill="white"
    />
    <path
      d="M5.57869 7.99941H1.62092C1.16813 7.99941 0.799805 7.64056 0.799805 7.19941C0.799805 6.75827 1.16813 6.39941 1.62092 6.39941H5.57869C6.03148 6.39941 6.3998 6.75827 6.3998 7.19941C6.3998 7.64056 6.03148 7.99941 5.57869 7.99941Z"
      fill="white"
    />
    <path
      d="M5.57869 11.599H1.62092C1.16813 11.599 0.799805 11.2402 0.799805 10.799C0.799805 10.3579 1.16813 9.99902 1.62092 9.99902H5.57869C6.03148 9.99902 6.3998 10.3579 6.3998 10.799C6.3998 11.2402 6.03148 11.599 5.57869 11.599Z"
      fill="white"
    />
    <path
      d="M5.57869 15.1996H1.62092C1.16813 15.1996 0.799805 14.8408 0.799805 14.3996C0.799805 13.9585 1.16813 13.5996 1.62092 13.5996H5.57869C6.03148 13.5996 6.3998 13.9585 6.3998 14.3996C6.3998 14.8408 6.03148 15.1996 5.57869 15.1996Z"
      fill="white"
    />
    <path
      d="M5.57869 18.7992H1.62092C1.16813 18.7992 0.799805 18.4404 0.799805 17.9992C0.799805 17.5581 1.16813 17.1992 1.62092 17.1992H5.57869C6.03148 17.1992 6.3998 17.5581 6.3998 17.9992C6.3998 18.4404 6.03148 18.7992 5.57869 18.7992Z"
      fill="white"
    />
    <path
      d="M5.57869 22.3998H1.62092C1.16813 22.3998 0.799805 22.0409 0.799805 21.5998C0.799805 21.1587 1.16813 20.7998 1.62092 20.7998H5.57869C6.03148 20.7998 6.3998 21.1587 6.3998 21.5998C6.3998 22.0409 6.03148 22.3998 5.57869 22.3998Z"
      fill="white"
    />
    <path
      d="M5.57869 25.9994H1.62092C1.16813 25.9994 0.799805 25.6406 0.799805 25.1994C0.799805 24.7583 1.16813 24.3994 1.62092 24.3994H5.57869C6.03148 24.3994 6.3998 24.7583 6.3998 25.1994C6.3998 25.6406 6.03148 25.9994 5.57869 25.9994Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup>
import { iconAttributes } from '@/utils/icons';

const attrs = iconAttributes({ width: 28, height: 32 });
</script>
