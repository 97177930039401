<template>
  <component
    :is="link ? 'router-link' : 'div'"
    :to="link"
    :class="['card', { hold: !card.active }]"
  >
    <picture>
      <source
        v-for="(srcset, media) in mediaSrcsets"
        :key="media"
        :media="media"
        :srcset="srcset"
      />

      <img
        :src="fallbackSrc"
        :srcset="fallbackSrcset"
        :alt="card.name"
        class="card__img"
      />
    </picture>
    <div class="card__front">
      <div
        v-if="card.active_for_user && licenseStateLabel"
        class="card__subscribe"
      >
        {{ licenseStateLabel }}
      </div>
      <div class="card__hold">
        {{ t("labs.soon") }}
      </div>
      <div class="card__subject">
        {{ card.subsection.name }}
      </div>
      <div class="card__title">
        {{ card.name }}
      </div>
      <div
        v-if="card.has_workbook"
        class="card__workbook"
      >
        <IconWorkbook />
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { LocationAsRelativeRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import { imageCandidatesToSrcset } from '@/utils';

import IconWorkbook from '@/components/icons/Workbook.vue';

import { LabData } from '../types';

const props = defineProps<{
  card: LabData;
}>();

const { t } = useI18n();

const link = computed<LocationAsRelativeRaw | undefined>(() => {
  if (props.card.active) {
    return { name: names.laboratory, params: { id: props.card.id } };
  }
  return undefined;
});

const appImg = toRef(() => props.card.app_img);

// 660px = (300px column) * 2 + (20px gap) + (20px padding) * 2
const mediaSrcsets = computed(() => ({
  '(max-width: 659px)': imageCandidatesToSrcset({
    '1x': appImg.value['800x560'],
  }),
  '(min-width: 660px)': imageCandidatesToSrcset({
    '1x': appImg.value['400x280'],
    '2x': appImg.value['800x560'],
  }),
}));

const fallbackSrc = computed(() => appImg.value['240x160']);

const fallbackSrcset = computed(() => imageCandidatesToSrcset({
  '1x': appImg.value['400x280'],
  '2x': appImg.value['800x560'],
}));

const licenseStateLabel = computed(() => {
  if (typeof props.card.license_state === 'boolean') {
    return null;
  }
  const shortName = props.card.license_state.replace('licenses.license_state_', '');
  return t(`labs.licenseState.${shortName}`);
});
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.card {
  border-radius: 14px;
  border: 3px solid $color-white;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:is(a) {
    cursor: pointer;
    transition: border-color $transition-speed;

    @include hover-focus() {
      border-color: $color-cyan-menu;
    }
  }
}

.card__front {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding: 18px;
  position: absolute;
  top: 0;
  width: 100%;
}

.card__img {
  height: 100%;
  width: 100%;
  min-height: 200px;
}

.card.hold .card__img {
  opacity: 0.5;
}

.card__subscribe {
  background: $color-green;
  border-radius: 10px;
  color: $color-white;
  padding: 4px 8px;
  width: fit-content;
}

.card__subject {
  background: $color-accent;
  border-radius: 10px;
  color: $color-white;
  margin-bottom: 10px;
  margin-top: auto;
  padding: 4px 8px;
  text-transform: uppercase;
  width: fit-content;
}

.card__title {
  background: $color-accent;
  box-shadow: $box-shadow-white;
  color: $color-white;
  font-size: 22px;
  line-height: 1.2;
  margin-left: 3px;
  padding: 2px 8px 4px;
  width: fit-content;
}

.card__bookmark {
  opacity: 0;
  position: absolute;
  right: 20px;
  top: 0;
  transition: opacity $transition-speed;
}

.card:hover .card__bookmark {
  opacity: 1;
}

.card__hold {
  border-radius: 12px;
  border: 7px solid $color-red-darkest;
  color: $color-red-darkest;
  display: none;
  font-size: 28px;
  font-weight: 800;
  left: 50%;
  padding: 5px 25px;
  position: absolute;
  text-transform: uppercase;
  top: 32%;
  transform: translate(-50%, -50%) rotate(-22deg);
  width: fit-content;
}

.card.hold .card__hold {
  display: block;
}

.card.hold {
  & .card__bookmark,
  & .card__subject,
  & .card__title {
    background: var(--color-grey-labs-category);
  }
}

.card__workbook {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  border-bottom-left-radius: 12px;
  background: $color-peach;
  font-size: 34px;
}
</style>
