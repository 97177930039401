<template>
  <ModalMenu
    class="nav-wrapper"
    :isShow="isShow"
    @close="handleClose"
  >
    <template #button="{ buttonClass }">
      <button
        class="nav-toggle"
        :class="buttonClass"
        @click="handleToggle"
      >
        {{ t(firstLevelMenuTitle) }}
      </button>
    </template>
    <template #content>
      <ModalMenuItem
        v-for="(element, index) in menu"
        :key="`navLink${index}`"
        :to="element.link"
        @click="handleClose"
      >
        {{ t(element.title) }}
      </ModalMenuItem>
    </template>
  </ModalMenu>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { mainMenu, MenuItem } from '@/constants/menus';
import ModalMenu from '@/components/Header/ModalMenu.vue';
import ModalMenuItem from '@/components/Header/ModalMenuItem.vue';


const store = useStore();
const route = useRoute();
const { t } = useI18n();

const userRole = computed<string>(() => store.getters.role);

const isShow = ref<boolean>(false);

const menu = computed<Array<MenuItem>>(
  () => mainMenu.filter(
    (menuLink) => menuLink.permissions.includes(userRole.value)
  )
);

const handleToggle = (): void => {
  isShow.value = !isShow.value;
};

const handleClose = (): void => {
  isShow.value = false;
};

const firstLevelMenuTitle = computed(() => {
  const routeMatchedNames = route.matched.map(
    (matchedRoute) => matchedRoute.name
  );

  console.log(route);

  const currentMenuItem = menu.value.find((menuItem) => {
    if (typeof menuItem.link === 'string' || !('name' in menuItem.link)) {
      return false;
    }

    return routeMatchedNames.includes(menuItem.link.name);
  });

  const routeName = typeof route.name === 'string'
    ? route.name
    : 'portal';

  return currentMenuItem?.title || routeName.toLowerCase();
});
</script>

<style scoped lang="scss">

.nav-wrapper {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.nav-toggle {
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
