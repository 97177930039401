import { createApp } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Maska from 'maska';

import 'nprogress/nprogress.css';
import '@/assets/style/main.scss';

import { setLocale } from '@/composition/useLangSet';

import { userAuthentication } from '@/router/middleware/auth';
import withIframeAuth from '@/utils/iframeKeycloakAuth';
import websocketConnect from '@/services/websocket';

import App from './App.vue';

// Redirect in router payment-success
import router, { getRoutes } from './router';
import store from './store';
import i18n from './i18n';
import { mountApp } from './mountApp';

const launchFunc = async () => {
  let role = '';
  await withIframeAuth.then(async (flag) => {
    if (flag) {
      role = await userAuthentication(store);
    }
  });
  const routes: Array<RouteRecordRaw> = await getRoutes(role);
  routes.forEach((route: RouteRecordRaw) => router.addRoute(route));
  if (role?.length > 0) {
    await websocketConnect();
  }

  // Set app language
  setLocale();

  const app = createApp(App)
    .use(i18n)
    .use(Maska)
    .use(store)
    .use(router);

  mountApp(app);
};

launchFunc();

// Show portal version
const version = process.env.VUE_APP_VERSION !== ('master' || 'production')
  ? process.env.VUE_APP_VERSION
  : null;

console.log('v', version);
