import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';
import user from './User/routes';
import users from './Users/routes';

import requests from './Requests/routes';
import orders from './Orders/routes';
import licenses from './Licenses/routes';

export default <Array<RouteRecordRaw>>[
  {
    name: names.administration.organization.root,
    path: paths.administration.organization.root,
    redirect: paths.administration.organization.owner,
    meta: {
      permissions: permissions.administration.organization.root,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-org' */
      './index.vue'
    ),
    beforeEnter(to, from, next) {
      if (!to.params?.orgId) {
        next(from || { name: names.administration.root });
        return;
      }
      next();
    },
    children: [
      {
        name: names.administration.organization.owner,
        path: paths.administration.organization.owner,
        meta: {
          permissions: permissions.administration.organization.owner,
          isAuthed: false,
          requiresAuth: true,
        },
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: 'administration-org' */
          './Owner.vue'
        ),
        beforeEnter(to, from, next) {
          if (!to.params?.orgId) {
            next(from || { name: names.administration.root });
            return;
          }
          next();
        },
      },

      {
        name: names.administration.organization.createUser,
        path: paths.administration.organization.createUser,
        meta: {
          permissions: permissions.administration.organization.createUser,
          isAuthed: false,
          requiresAuth: true,
        },
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: 'administration-org' */
          './Users/NewUser.vue'
        ),
      },

      ...users,
      ...user,
      ...requests,
      ...orders,
      ...licenses,
    ],
  },
];
