import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

const user: Array<RouteRecordRaw> = [
  {
    name: names.administration.limb,
    path: paths.administration.limb,
    meta: {
      permissions: permissions.administration.limb,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-users' */
      './index.vue'
    ),
  },
];

export default user;
