import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';
import store from '@/store';
import { isSU } from '@/utils/user';

import users from './Users/routes';
import organizations from './Organizations/routes';
import organization from './Organization/routes';
import limb from './Limb/routes';

export const adminRedirect = computed<string>((): any => {
  const { role } = store.getters;

  const orgId = (store.state as any).UserData?.user?.organization?.id;
  if (isSU(role) || !orgId) {
    return { name: names.administration.users };
  }
  return {
    name: names.administration.organization.users,
    path: paths.administration.organization.users,
    params: { orgId },
  };
});

export const administration: Array<RouteRecordRaw> = [
  {
    name: names.administration.root,
    path: paths.administration.root,
    meta: {
      isAuthed: false,
      requiresAuth: true,
      permissions: permissions.administration.root,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration' */
      './index.vue'
    ),
    beforeEnter(to, from, next) {
      // Hack. Without that it makes cycle redirection on self;
      if (to.path !== paths.administration.root) {
        next();
        return;
      }
      next(adminRedirect.value);
    },
    children: [...users, ...organizations, ...organization, ...limb],
  },
];

export default administration;
