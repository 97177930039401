export const hasSpaceAtBottom = (
  el: HTMLElement
): boolean => {
  if (!window || !document || !document.body) {
    return true;
  }

  const elRect = el.getBoundingClientRect();

  if (elRect.top + window.scrollY > elRect.height) {
    const bodyHeight = Math.max(document.body.clientHeight, window.innerHeight);

    if (elRect.bottom + window.scrollY > bodyHeight) {
      return false;
    }
  }

  return true;
};

export const scrollTo = (
  target: HTMLElement,
  direction: ScrollLogicalPosition = 'start',
  behavior: ScrollBehavior = 'smooth'
): void => {
  target.scrollIntoView({
    behavior,
    block: direction,
  });
};

export const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export default {
  hasSpaceAtBottom,
  scrollTo,
  scrollToTop,
};
