<template>
  <nav class="nav">
    <router-link
      v-for="(element, index) in menu"
      :key="`navLink${index}`"
      :to="element.link"
      class="nav__link"
    >
      {{ t(element.title) }}
    </router-link>
  </nav>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { mainMenu, MenuItem } from '@/constants/menus';

const store = useStore();
const { t } = useI18n();

const userRole = computed<string>(() => store.getters.role);

const menu = computed<Array<MenuItem>>(
  () => mainMenu.filter(
    (menuLink) => menuLink.permissions.includes(userRole.value)
  )
);
</script>

<style scoped lang="scss">
@import "@/assets/style/include";

.nav {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  overflow-y: auto;
}

.nav__link {
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  background: $color-transparent;
  box-shadow: inset 0 -3px 0 $color-transparent;
  display: inline-flex;
  height: $header-height;
  padding: 22px 20px;
  transition: box-shadow 0.3s, background 0.3s;

  @include hover-focus() {
    background: $color-white-darkest;
    box-shadow: inset 0 0 0 3px $color-cyan-menu;
    outline: none;
  }

  @include media-breakpoint-down("sm") {
    height: $header-height-mobile;
    padding: 22px 8px;
  }
}

.nav__link.active {
  background: $color-white-darkest;
  box-shadow: inset 0 -3px 0 $color-cyan-menu;
  font-weight: 700;
}

.nav__link.active.exact {
  cursor: default;
}
</style>
