<template>
  <div
    class="search-switch"
    :class="{ 'active': isActive }"
  >
    <CleanInput
      ref="input"
      :value="value"
      :placeholder="placeholder"
      class="search-switch__input"
      @input="useInputEvent ? handleInput($event) : undefined"
      @keyup.enter="handleSearch"
    />

    <CleanButton
      class="search-switch__button"
      @click="handleSearch"
    >
      <Plus class="search-switch__close" />
      <IconMagnifier class="search-switch__find" />
    </CleanButton>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CleanInput from '@/components/inputs/CleanInput.vue';
import CleanButton from '@/components/CleanButton.vue';
import IconMagnifier from '@/components/icons/Magnifier.vue';
import Plus from '@/components/icons/Plus.vue';

interface Props {
  value?: string;
  placeholder?: string;
  useInputEvent?: boolean;
}

withDefaults(defineProps<Props>(), {
  value: '',
  placeholder: 'search',
  useInputEvent: false,
});

const emit = defineEmits<{
  (event: 'search', value: string): void
  (event: 'input', value: string): void
}>();

const handleInput = (val: string) => emit('input', val);

const input = ref(null);
const isActive = ref(false);

const handleSearch = () => {
  isActive.value = !isActive.value;

  const val = input.value?.$el.value;
  emit('search', val);
};

</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.search-switch {
  height: $input-height;
  position: relative;
  color: $color-primary;
  min-width: 200px;

  &__input {
    background: $color-white;
    border-radius: $input-height;
    border: 1px solid $color-primary;
    height: 100%;
    padding: 0.5rem 3rem 0.5rem 1rem;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: $color-primary;
      font-style: italic;
    }
  }

  &__button {
    align-items: center;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
  }
}

.search-switch__close {
  display: none;
}

@include media-breakpoint-down("sm") {

  .search-switch {
    height: 32px;
    min-width: 32px;
    position: relative;
    overflow: visible;

    &__button {
      width: 32px;
      border: 1px solid $color-purple;
      border-radius: 50%;
      color: $color-purple;
    }

    &.active {

      .search-switch__input {
        visibility: visible;
      }
    }
  }
  .search-switch__input {
    visibility:hidden;
    width: calc(100vw - 44px);
    position: absolute;
    right: 0;
    display: inline-block;
    box-shadow: 0 0 10px 10px white;
    font-size: 11px;
    border-color: $color-purple;

    &::placeholder {
      text-align: center;
      text-transform: lowercase;
    }
  }
  .search-switch__close {
    transform: rotate(45deg);
  }
}

</style>
