import NProgress from 'nprogress';
import {
  createRouter,
  createWebHistory,
  RouteMeta,
  RouteRecordRaw,
} from 'vue-router';
import i18n from '@/i18n';

import { names } from '@/constants/routes';
import { loginUser } from '@/services/userActions';
import YM from '@/composition/useYMetrica';

import shop from './Shop/routes';
import invoices from './Invoices/routes';
import price from './Price/routes';
import subscribe from './Subscriptions/routes';
import profile from './Profile/routes';
import administration from './Administration/routes';
import laboratories from './Laboratories/routes';
import alerts from './Alerts/router';
import develop from './Dev/router';

// redirect if payment-success
if (window.location.href.includes('/payment-success')) {
  window.history.replaceState({}, '', '/?payment=success');
}

const routes = [
  profile,
  shop,
  invoices,
  subscribe,
  ...administration,
  ...laboratories,
  ...price,
  ...alerts,
  ...develop,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [],
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact',
});

router.beforeEach(({ meta = {}, name }) => {
  if (meta.requiresAuth && !meta.isAuthed) {
    // Redirect user to log in with backlink
    return loginUser();
  }

  if (document.title) {
    const { t, te } = i18n.global;
    document.title = typeof name === 'string' && te(`pageTitles.${name}`)
      ? t(`pageTitles.${name}`)
      : t('portal');
  }

  NProgress.start();
});

router.afterEach((to, from) => {
  NProgress.done();

  if (to.path !== from.path) {
    YM.useHit(to.path, from.path);
  }
});

export const getRoutes = (
  role: string,
  givenRoutes: Array<RouteRecordRaw> = routes
) => {
  // Return default routes if user not authenticated
  if (!role) {
    return givenRoutes;
  }

  // Return modified routes array depends on role
  return givenRoutes.map((route: RouteRecordRaw) => {
    const meta: RouteMeta | any = route.meta || {};
    const children: Array<RouteRecordRaw> = route.children || [];
    const newRoute: RouteRecordRaw = {
      ...route,
      meta: {
        ...meta,
        isAuthed: true,
      },
    };

    // Modify each child route
    if (Array.isArray(children) && children.length > 0) {
      newRoute.children = getRoutes(role, children);
    }

    // Add redirect if role not permitted
    if (
      Array.isArray(meta.permissions)
      && meta.permissions.length > 0
      && !meta.permissions.includes(role)
    ) {
      newRoute.redirect = { name: names.laboratories };
    }

    // Return modified route
    return newRoute;
  });
};

export default router;
