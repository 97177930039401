import { AxiosResponse } from 'axios';
import { paths } from '@/constants/routes';

import axios from './axios';

const redirect = '/api/sso/redirect';

export const getToken = async (): Promise<void> => {
  await axios.get('/sanctum/csrf-cookie');
};

export const getUser = (): Promise<any> => axios
  .get('/me')
  .then((response: AxiosResponse) => response.data);

export const getUserProfile = (): Promise<any> => axios
  .get('/profile')
  .then((response: AxiosResponse) => response.data);

export const loginUser = (): void => {
  let { href } = window.location;
  href = href.replace(paths.alerts.closed, '');
  const pageBackLink = encodeURIComponent(href);
  document.location.href = `${redirect}?front=${pageBackLink}`;
};

export const logoutUser = async (go?: string): Promise<void> => {
  try {
    const { location } = await axios
      .post(`/sso/logout/all?front=${process.env.VUE_APP_REDIRECT}`)
      .then((response: AxiosResponse) => response.data);

    if (go) {
      document.location.href = go;
    } else {
      document.location.href = location;
    }
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = (): void => {
  document.location.href = `${redirect}UpdatePassword?front=${process.env.VUE_APP_REDIRECT}`;
};

export const getWebsocketData = (): Promise<any> => axios
  .get('/centrifugo/refresh')
  .then((response: AxiosResponse) => response.data);

export const setLang = (lang: string): Promise<any> => axios
  .post('/set-lang', { lang })
  .then((response) => response.data);

export default {
  getToken,
  getUser,
  getUserProfile,
  loginUser,
  logoutUser,
  resetPassword,
  getWebsocketData,
  setLang,
};
