<template>
  <div
    v-if="recent && recent.length > 0"
    class="block"
  >
    <h2 class="block__title">
      {{ t("labs.recent") }}
    </h2>
    <div class="recent">
      <LabsRecentCard
        v-for="card in recent"
        :key="`recent${card.id}`"
        :card="card"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { LabData } from '../types';
import LabsRecentCard from './RecentCard.vue';


defineProps<{
  recent: LabData[];
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.recent {
  display: flex;
  gap: 11px;
  width: max-content;
}
.block {
  grid-gap: 12px;
}
</style>
