import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

const price: Array<RouteRecordRaw> = [
  {
    path: paths.price,
    name: names.price,
    meta: {
      permissions: permissions.price,
      requiresAuth: true,
      isAuthed: false,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'price' */
      './index.vue'
    ),
  },
];

export default price;
