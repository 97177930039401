export const inputKeysMap = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  TAB: 9,
};

export default {
  inputKeysMap,
};
