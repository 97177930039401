export default {
  "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail"])},
  "vrLabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoires virtuels"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurer"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "loader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сhargement..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiré"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
  "half_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demi-année"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
  "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non sélectionné"])},
  "goByLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprimer"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié!"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copier dans le tampon"])},
  "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement réussi"])},
  "typeTextToFind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le texte à rechercher"])},
  "sessionCloseByServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La session est close. Il est nécessaire de passer à nouveau la procédure d'authentification."])},
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])},
  "alerts": {
    "sessionClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture de la session"])},
    "sessionCloseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, cela arrive. Pour revenir, connectez-vous avec votre compte."])}
  },
  "pageTitles": {
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutique"])},
    "Laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratories"])},
    "Laboratory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laboratoire ", _interpolate(_named("name"))])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "SessionClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session fermée"])},
    "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "AdministrationUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Utilisateurs"])},
    "AdministrationUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Utilisateur"])},
    "AdministrationLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Licences"])},
    "AdministrationRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Requêtes"])},
    "AdministrationUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Création d'utilisateurs"])},
    "AdministrationLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Limbo"])},
    "AdministrationOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Établissement"])},
    "AdministrationOrgOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Propriétaire de l'établissement"])},
    "AdministrationOrgUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Utilisateurs de l'institution"])},
    "AdministrationOrgUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Utilisateur de l'établissement"])},
    "AdministrationOrgOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Achats de l'établissement"])},
    "AdministrationOrgRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Demandes d'établissement"])},
    "AdministrationOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Demande d'établissement"])},
    "AdministrationOrgLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Licences d'établissement"])},
    "AdministrationOrgUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Création d'utilisateurs dans l'établissement"])},
    "AdministrationOrganizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Institutions"])},
    "AdministrationOrgCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration ", "|", " Création d'institution"])}
  },
  "labels": {
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "patronymic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patronyme"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue du site et des applications"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["École"])},
    "organization1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "studyClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'établissement d'enseignement"])},
    "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "localRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôles locaux"])},
    "requestedRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle demandé"])},
    "requestedClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe demandée"])},
    "fileAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre un fichier"])},
    "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
    "addGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un groupe"])},
    "notSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non spécifié"])},
    "classNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de classe"])},
    "groupNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de groupe"])},
    "founderData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données du fondateur"])}
  },
  "roles": {
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur du portail"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modérateur"])},
    "marketer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketeur"])},
    "org_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire de l'établissement"])},
    "org_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modérateur de l'institution"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élève"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ doit être rempli."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ doit contenir un minimum de ", _interpolate(_named("min")), " caractères."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ doit contenir un maximum de ", _interpolate(_named("max")), " caractères."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ doit être une adresse e-mail valide."])},
    "datepickerRangeOverflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date trop longue"])},
    "datepickerRangeUnderflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop petite date"])},
    "datepickerTypeMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez la date au format JJ.mm. AAAA"])},
    "valueMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur obligatoire à remplir"])},
    "incorrectGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de groupe incorrect"])}
  },
  "filters": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
    "vizex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Place"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autrui"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements"])},
    "buttons": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'essai"])}
    }
  },
  "menus": {
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoires"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes d & apos; adhésion"])},
    "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutions"])},
    "limb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limbe"])},
    "ownersRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidatures aux fondateurs"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrée"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortie"])}
  },
  "footer": {
    "publicOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre publique"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d'utilisation"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance technique"])},
    "systemRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration système requise"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Version: ", _interpolate(_named("version"))])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Vizex Info Ltd., 2013–", _interpolate(_named("date"))])},
    "copyrightInternational": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© Code Place Ltd., 2023–", _interpolate(_named("date"))])}
  },
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence"])},
  "licenses": {
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuels"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De l'institution"])}
  },
  "autoPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement automatique"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "packet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquet"])},
  "allInclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout compris"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'abonner à"])},
  "issueTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donner avant"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr?"])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrouiller"])},
  "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverrouiller"])},
  "banUnban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrouiller / Déverrouiller"])},
  "createNewOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouvel utilisateur"])},
  "orgKick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclure de l'établissement"])},
  "leaveOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détachement"])},
  "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un groupe"])},
  "createModerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un modérateur"])},
  "createOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une institution"])},
  "changeOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'institution"])},
  "deleteOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'établissement"])},
  "deleteUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Suppression d'un utilisateur"]), _normalize(["Suppression d'un utilisateur"]), _normalize(["Suppression d'un établissement"])])},
  "deleteUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Attention, cette action est irréversible, les utilisateurs supprimés ne peuvent pas être restaurés."]), _normalize(["Êtes-vous sûr de vouloir supprimer l'utilisateur?"]), _normalize(["Vous êtes sûr de vouloir supprimer l'établissement?"])])},
  "banUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Verrouillage de l'utilisateur"]), _normalize(["Verrouillage de l'utilisateur"]), _normalize(["Verrouillage du fondateur"])])},
  "banUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Le blocage limitera l'accès de l'utilisateur aux laboratoires et, si l'utilisateur est le fondateur, il ne pourra pas gérer son établissement."]), _normalize(["Le blocage limitera l'accès de l'utilisateur aux laboratoires."])])},
  "banReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif de l'interdiction"])},
  "unbanUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Déverrouiller l'utilisateur"]), _normalize(["Déverrouiller le fondateur"])])},
  "unbanUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Le déverrouillage rétablira l'accès de l'utilisateur aux laboratoires."]), _normalize(["Le déblocage rétablira l'accès du fondateur à la gestion de l'établissement."])])},
  "kickUserFromOrgModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusion de l'institution"])},
  "kickUserFromOrgModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exception entraînera la perte de l'accès de l'utilisateur aux licences émises."])},
  "usersTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "added_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joint"])},
    "registered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode d'inscription"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])}
  },
  "orgsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intitulé"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
    "licenses_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondateur"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joint"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "requestsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ Candidatures"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitas"])},
    "statusDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la décision"])}
  },
  "numerals": {
    "labs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nn")), " laboratoire"]), _normalize([_interpolate(_named("nn")), " laboratoires"]), _normalize([_interpolate(_named("nn")), " laboratoires"])])},
    "students": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["élève"]), _normalize(["élève"]), _normalize(["élève"])])},
    "teachers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["enseignant"]), _normalize(["enseignant"]), _normalize(["enseignant"])])},
    "users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["utilisateur"]), _normalize(["utilisateur"]), _normalize(["utilisateur"])])},
    "createUsers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["utilisateur"]), _normalize(["utilisateur"]), _normalize(["utilisateur"])])},
    "moderators": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["modérateur"]), _normalize(["modérateur"]), _normalize(["modérateur"])])}
  },
  "emailNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["courrier en attente de confirmation"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quelque chose a mal tourné..."])},
  "userCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur créé avec succès"])},
  "userCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un autre"])},
  "emptyUserName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utilisateur №", _interpolate(_named("id"))])},
  "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rien trouvé"])},
  "createOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création D'Une Institution"])},
  "organizationCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учреждение успешно создано"])},
  "organizationCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement créé avec succès"])},
  "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoire"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement automatique"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'essai"])},
  "youHaveNLaunchesLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Il vous reste ", _interpolate(_named("nn")), " lancement"]), _normalize(["Il vous reste ", _interpolate(_named("nn")), " lancements"]), _normalize(["Il vous reste ", _interpolate(_named("nn")), " lancements"])])},
  "usersListTableLeaveBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous accédez à la page d'un utilisateur, la sélection sur cette page sera perdue"])},
  "AdministrationOrdersConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de paiement"])},
  "AdministrationOrdersConfirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas annuler un paiement confirmé"])},
  "AdminOrgOrdersToastOrderConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande payée!"])},
  "ToastOrderDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande supprimée!"])},
  "ToastSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvé!"])},
  "OrgShopToastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tous les champs correctement"])},
  "ShopOrgTableAddNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajouter lot"])},
  "ShopOrgTableAddNewProductError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez la commande avant de créer une nouvelle"])},
  "ShopSendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande expédiée"])},
  "universalEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
  "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adopter"])},
  "declineRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la demande"])},
  "acceptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et accepter la demande"])},
  "actionConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'action en cours"])},
  "statuses": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attend"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptas"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejetée"])}
  },
  "searchByContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par contexte"])},
  "searchByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par titre"])},
  "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionné"])},
  "notEnoughLicensesToDoThisAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licences insuffisantes pour une action"])},
  "fileExtensionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier \"", _interpolate(_named("name")), "\" n'a pas un format valide (", _interpolate(_named("extension")), ")."])},
  "exportFromFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "importFileWithError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le fichier d'erreur"])},
  "exported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportable"])},
  "subscribePage": {
    "confirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour activer le paiement automatique, vous devez effectuer un achat de 1 eur pour confirmer la carte."])},
    "emptyPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, vous n'avez pas encore d'abonnements payants, allez au magasin, il y a beaucoup de choses intéressantes!"])},
    "packetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les laboratoires sont à votre disposition:"])},
    "toShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le magasin"])}
  },
  "shop": {
    "invoiceName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["№ ", _interpolate(_named("num"))])},
    "textNotAuthorizedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous abonner au laboratoire, créez un compte et confirmez votre courrier"])},
    "autopaymentLabelAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paiement automatique (peut être annulé plus tard dans votre compte personnel)"])},
    "nextPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prochain paiement ", _interpolate(_named("date")), ":"])},
    "nextPaymentSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("sum")), " par mois"]), _normalize([_interpolate(_named("sum")), " par six mois"]), _normalize([_interpolate(_named("sum")), " par an"])])},
    "buy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Acheter sélectionné"]), _normalize(["Acheter"])])},
    "personalDataProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des données personnelles"])},
    "publicOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offre publique"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions d'utilisation"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
    "personalDataProcessingInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En poursuivant votre achat, vous acceptez ", _interpolate(_list(0)), ", ", _interpolate(_list(1)), " et ", _interpolate(_list(2)), "."])},
    "periodPayment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["pour tous les laboratoires pour 1 mois"]), _normalize(["pour tous les laboratoires pour 6 mois"]), _normalize(["pour tous les laboratoires pour 12 mois"])])},
    "tillDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["jusqu'à ", _interpolate(_named("date"))])},
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une commande"])},
    "sendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une commande"])},
    "orgEmptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder aux laboratoires et distribuer des licences à vos étudiants et enseignants, passez une commande et suivez les instructions ci-dessous."])},
    "orgEmptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'accès pour l'organisation?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numéro"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Être vide"])},
    "closeShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin est actuellement fermé"])},
    "unavailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin est temporairement indisponible"])},
    "unavailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez nous contacter si vous souhaitez acheter une licence à :"])},
    "unavailableForUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear friends!\nSummer is not only a wonderful time of year and school holidays, summer is also a time of change!\nWe are temporarily closing our store to return to you with a new concept and new solutions for education.\nAnd, of course, everyone who has already purchased a subscription to our virtual laboratories will be able to continue to use it.\n⁠\nAlways with you, VR-Labs team."])},
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoires"])},
    "ordersListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des commandes"])},
    "notPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non payé"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amorti"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achevé"])},
    "noStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non défini"])}
  },
  "administration": {
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un utilisateur"])},
    "classNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H"])}
    ],
    "org": {
      "actions": {
        "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détacher"])},
        "kickModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désengagement de l'institution"])},
        "kickModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez prudent. Les utilisateurs perdront l'accès aux licences accordées par leur organisation."])},
        "kickModalTextSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez prudent. L'utilisateur perd l'accès aux licences accordées par l'organisation."])},
        "waitingQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous attendons la file d'attente..."])},
        "creatingUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des utilisateurs..."])},
        "usersCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs créés..."])},
        "handleErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous traitons les erreurs..."])},
        "exportingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter un fichier"])},
        "downloadFileWithErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le document d'erreur"])},
        "brokenFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier ne répond pas aux exigences, essayez un autre fichier."])},
        "accountsCreated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comptes créés: ", _interpolate(_named("created")), " à partir de ", _interpolate(_named("count"))])},
        "accountsCreatedText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Impossible de créer ", _interpolate(_named("error")), ". Nous avons signalé des erreurs dans les lignes correspondantes. Corrigez les erreurs et téléchargez à nouveau le document."])}
      },
      "fileFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le document Téléchargé ne correspond pas au format requis"])},
      "fileName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fichier de ", _interpolate(_named("date"))])},
      "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un groupe d'utilisateurs (XLS)"])},
      "inviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien d'invitation"])},
      "userRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de l'utilisateur: "])},
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
      "changeClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la classe"])},
      "groupDeleteConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Pour supprimer ", _interpolate(_list(0)), ", saisissez son nom dans le champ ci-dessous."])},
      "noInviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le moment, vous n'avez pas de lien actif pour rejoindre votre établissement"])},
      "infoTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ce bouton ouvre le panneau d'informations sur votre établissement d'enseignement."]), _normalize(["Ici, vous pouvez créer et modifier des groupes dans lesquels vous réunirez vos élèves et vos enseignants. De cette façon, vous pouvez gérer les succursales ou séparer les modérateurs des étudiants."]), _normalize(["Pour partager le lien d'adhésion à votre Organisation - cliquez sur le bouton à droite"])])},
      "actionFileCreateTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pour ajouter plusieurs utilisateurs à la fois, téléchargez le fichier au format «.xls». Le nom, le rôle et l'adresse E-mail des utilisateurs doivent être indiqués dans le contenu du fichier. Les utilisateurs seront invités à s'inscrire à l'établissement d'enseignement aux adresses indiquées."]), _normalize(["Si vos élèves n'ont pas d'adresse e-mail, vous pouvez les inviter à rejoindre l'établissement à l'aide d'un code QR ou d'un lien d'invitation."])])},
      "downloadFileTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le modèle .xls"])}
    },
    "limb": {
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposé"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banni"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimas"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
      "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué"])}
    }
  },
  "profile": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe >"])},
    "waitingForConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
    "deleteModalMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer votre compte? Si vous rencontrez des problèmes avec votre compte, écrivez-nous à ", _interpolate(_list(0)), " et nous essaierons de vous aider."])},
    "subscribeMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnez-vous à la newsletter"])},
    "deleteModalMessageOrg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer votre compte et votre établissement? Si vous rencontrez des problèmes avec votre compte, écrivez-nous à ", _interpolate(_list(0)), " et nous essaierons de vous aider."])},
    "deleteModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer un compte"])},
    "deleteModalHeaderOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'un établissement"])},
    "restoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurer le compte"])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])},
    "deleteButtonOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'établissement"])},
    "revokeOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la demande"])},
    "revokeOrgRequestText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous annuler votre demande d'adhésion à “", _interpolate(_named("org")), "”?"])},
    "leaveOrgButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se désengager de l'école >"])},
    "leaveOrgModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désengagement de l'institution"])},
    "leaveOrgModalConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se détacher"])},
    "leaveOrgText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous quittez un établissement, vous n'aurez plus accès aux abonnements que vous avez émis par cet établissement."])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une demande"])},
    "modalTitleRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration de l'utilisateur"])},
    "modalTextRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment restaurer cet utilisateur?"])},
    "attachToOrgText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous soumettre une demande d'adhésion à “", _interpolate(_named("org")), "”?"])},
    "disabledDeleteOrgTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer une institution tant qu'elle a quelqu'un d'autre que le fondateur"])},
    "accepted": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande est acceptée!"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons accepté votre demande d'admission dans un établissement d'enseignement. Vous pouvez voir le statut de la demande ou l'annuler dans votre profil."])}
    },
    "consists": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà membre de cette institution"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations, rien de mal n'est arrivé. Vous avez simplement essayé de réintégrer une institution dans laquelle vous êtes déjà membre"])}
    },
    "consists-another": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà membre d'une institution"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, il est impossible de rejoindre plusieurs institutions en même temps. Si vous souhaitez changer d'établissement, détachez-vous d'abord de l'établissement actuel"])}
    },
    "confirmation-await": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande est en cours d'examen"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons accepté votre demande d'admission. Votre demande est en attente"])}
    },
    "incorrect-role": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malheureusement, vous ne pouvez pas suivre ce lien"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifiez le rôle de votre profil ou demandez à l'administrateur de le modifier"])}
    }
  },
  "labsSystemRequirements": {
    "baseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration système requise"])},
    "labels": {
      "processor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processeur: "])},
      "RAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mémoire RAM: "])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte graphique: "])},
      "videoIntegrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "videoExternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résolution d'écran: "])},
      "browsersSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigateurs pris en charge: "])},
      "operationSystemsSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes d'exploitation pris en charge: "])}
    },
    "descriptions": {
      "processor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intel Ivy Bridge Series / AMD Bulldozer Series et plus récent"])},
      "RAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Gb ou plus"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "videoIntegrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intégré: Intel HD Graphics 5000 / Intel Iris et plus récent"])},
      "videoExternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discret: GeForce 400 série ou plus récent / Radeon HD 7000 série ou plus récent"])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1920x1080 / 1920x1200 et plus (les écrans 4K et Retina nécessitent une carte vidéo de plus grande puissance pour un fonctionnement confortable)"])},
      "browsersSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome, Opera 44+, Firefox 51+, Edge 79+, Safari 15+"])},
      "operationSystemsSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Windows 7 (version x64) ou supérieure avec prise en charge des pilotes WebGL 2.0, MacOS X «Snow Leopard» 10.6 ou supérieure avec prise en charge des pilotes WebGL 2.0, Linux ou Debian avec prise en charge des pilotes WebGL 2.0"])}
    },
    "checkWebGL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour savoir si votre appareil prend en charge WebGL 2.0, vous pouvez suivre le lien - "])}
  },
  "price": {
    "currencyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])},
    "currencySign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
    "monthPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / 1 mois"])},
    "halfYearPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / 6 Demi-année"])},
    "yearPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / 1 année"])},
    "salePercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remise, %"])}
  },
  "labs": {
    "section": {
      "labs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoires"])},
      "teaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils méthodologiques"])},
      "scientific_stands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stands scientifiques"])},
      "tournaments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tournois"])}
    },
    "licenseState": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démo"])},
      "own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accordé par l'organisation"])}
    },
    "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récents"])},
    "emptySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hélas, rien n'a été trouvé sur votre question. Et si vous cherchiez quelque chose dans notre bibliothèque?"])},
    "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt!"])},
    "fromPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["de ", _interpolate(_named("price"))])},
    "launch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'abonner à"])},
    "workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classeur"])},
    "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["démo"])},
    "virtualLab": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laboratoire virtuel «", _interpolate(_named("name")), "»"])},
    "subscriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnez-vous dès maintenant et accédez au laboratoire!"])},
    "oneRubSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnez-vous dès maintenant et accédez au laboratoire!"])},
    "clearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'accord"])},
    "mobileModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application n'est pas encore disponible sur les appareils mobiles."])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups..."])}
  }
}