import axios from '@/services/axios';

const getLaboratoriesList = () => axios
  .get('/products')
  .then((response) => response.data);

const getPublicLaboratoriesList = () => axios
  .get('/public/products')
  .then((response) => response.data);

const getLaboratoryById = (id: number) => axios
  .get(`/products/${id}`)
  .then((response) => response.data);

const getPublicLaboratoryById = (id: number) => axios
  .get(`/public/products/${id}`)
  .then((response) => response.data);

const getLaboratoryDemoLink = (id: number): Promise<{ url: string }> => axios
  .get(`products/${id}/demo`)
  .then((response) => response.data);

const getAppLink = (id: number): Promise<{ url: string }> => axios
  .get(`products/${id}/url`)
  .then((response) => response.data);

export default {
  getLaboratoriesList,
  getPublicLaboratoriesList,
  getLaboratoryById,
  getPublicLaboratoryById,
  getLaboratoryDemoLink,
  getAppLink,
};
