import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

export default <Array<RouteRecordRaw>>[
  {
    name: names.administration.organization.orders,
    path: paths.administration.organization.orders,
    meta: {
      permissions: permissions.administration.organization.orders,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-org' */
      './index.vue'
    ),
    beforeEnter(to, from, next) {
      if (!to.params?.orgId) {
        next(from || { name: names.administration.root });
        return;
      }
      next();
    },
  },
];
