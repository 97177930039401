import { ValidationArgs } from '@vuelidate/core';
import { ProfileField } from '@/types';
import { suRoles } from '@/constants/roles';

import { maxLength, minLength, required } from '@/utils/validations';
import {
  role,
  firstname,
  surname,
  patronymic,
  local_roles,
  organization,
  email,
} from '@/constants/input-fields';

export const tabs = {
  profile: 'profile',
  licenses: 'licenses',
};

export const UserProfileFields: Record<string, ProfileField> = {
  role,
  firstname: { ...firstname, required: true },
  surname: { ...surname, required: true },
  patronymic,
  local_roles,
  organization: {
    ...organization,
    apiName: 'org_id',
    can_edit: suRoles,
  },
  email: { ...email, can_edit: [] },
};

export const rulesMap: ValidationArgs = {
  role: {
    value: {
      required,
    },
  },
  firstname: {
    value: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
  surname: {
    value: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
  patronymic: {
    value: {
      minLength: minLength(2),
      maxLength: maxLength(32),
    },
  },
  email: {
    value: {},
  },
};
