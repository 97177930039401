<template>
  <div class="filter">
    <div class="filter__buttons">
      <button
        v-for="(button, index) in filters.values()"
        :key="`btn-${index}`"
        :class="['toggle__btn', { active: button?.active }]"
        type="button"
        @click="addActive(button.shortname)"
      >
        {{ button?.name }}
        <span>
          ({{ button.count }})
        </span>
      </button>
    </div>


    <SearchSwitch
      :value="filterWord || ''"
      :placeholder="t('search')"
      useInputEvent
      @input="handleSearchInput"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import SearchSwitch from '@/components/inputs/SearchSwitch.vue';
import { FilterButton } from '../types';

defineProps<{
  filters: Map<string, FilterButton>;
  filterWord?: string;
}>();

const emit = defineEmits<{
  (ev: 'update:group', key: string): void;
  (ev: 'update:search', type: string): void;
}>();

const { t } = useI18n();

// METHODS
const addActive = (key: string) => {
  emit('update:group', key);
};

const handleSearchInput = (val: string) => {
  emit('update:search', val);
};
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.filter__buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.toggle__btn {
  margin: 0 0.5rem 1rem 0;
}

@include media-breakpoint-down("sm") {
  .filter {
    gap: 0;
  }
  .filter__buttons {
    width: 85%;
    overflow: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
  }
  .filter__buttons::-webkit-scrollbar {
    display: none;
  }
  .toggle__btn {
    padding: 0 12px;
    line-height: 32px;
    opacity: 1;
    font-size: 11px;
    font-weight: 700;
    box-sizing: content-box;
    border-radius: 12% 12% 12% 12%/44% 44% 40% 40%;
    border: 1px solid $color-primary-light;
    box-shadow: 0px -3px 0px 0px $color-primary-light inset;
    margin-bottom: 0;
    transition: .5s box-shadow, .5s border-color;

    &.active {
      border-color: $color-purple;
      box-shadow: 0px -3px 0px 0px $color-purple inset;
    }
  }
}
</style>
