import { useEventListener } from '@/libraries/useEventListener';
import { throttle } from '@/utils/debounce';

export const useWindowScroll = (callback: Function, delay: number): void => {
  useEventListener(
    window,
    'scroll',
    throttle(() => callback(), delay)
  );
};

export default {
  useWindowScroll,
};
