import axios from '@/services/axios';
import { AutoPay, SubscribeOrg, SubscribeUser } from './types';

export const getUserSubscriptions = (): Promise<Array<SubscribeUser>> => axios.get('/subscriptions').then((response) => response.data);

export const getOrgSubscriptions = (): Promise<Array<SubscribeOrg>> => axios.get('/org-subscriptions').then((response) => response.data);

export const disabledAutoPay = (
  value: AutoPay
): Promise<void> => axios.post('/disable-auto-pay', value);

export const updateAutoPay = (
  value: AutoPay
): Promise<void> => axios.post('/change-auto-renewal-period', value);

export const enableAutoPay = (
  value: AutoPay
): Promise<Record<string, unknown>> => axios.post('/enable-auto-pay', value).then((response) => response.data);
