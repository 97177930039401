<template>
  <div class="lab__details">
    <h2 class="lab__details-heading">
      {{ t("license") }}
    </h2>
    <p v-if="isAutoRenewal">
      {{ t("auto-renewal") }} {{ formatDate(expires) }}
    </p>
    <p v-else>
      {{ t("expires") }} {{ formatDate(expires) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserLicenses } from '@/composition/useUserLicenses';
import { SubscribeOrg, SubscribeUser } from '@/router/Subscriptions/types';
import { formatDate } from '@/utils';

const { t } = useI18n();

const props = defineProps<{
  license: SubscribeUser | SubscribeOrg;
}>();

const { expires } = useUserLicenses(
  toRef(props, 'license')
);

const isAutoRenewal = computed(
  () => Boolean(props.license.is_auto)
);
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.lab__details {
  color: $color-blue-darkest;
}

.lab__details-heading {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}


@include media-breakpoint-down("md") {
  .lab__details {
    text-align: center;
    grid-column: -1/1;
  }
}
</style>
