import { RouteRecordRaw } from 'vue-router';
import { names, paths, permissions } from '@/constants/routes';

const administration: Array<RouteRecordRaw> = [
  {
    name: names.administration.organizations,
    path: paths.administration.organizations,
    meta: {
      isAuthed: false,
      requiresAuth: true,
      permissions: permissions.administration.organizations,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-org-list' */
      './index.vue'
    ),
    children: [
      {
        name: names.administration.createOrg,
        path: paths.administration.createOrg,
        meta: {
          isAuthed: false,
          requiresAuth: true,
          permissions: permissions.administration.createOrg,
        },
        component: () => import(
          /* webpackPrefetch: true */
          /* webpackChunkName: 'administration-org-list' */
          './NewOrganization.vue'
        ),
      },
    ],
  },
];

export default administration;
