export default {
  "portal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Портал"])},
  "vrLabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные лаборатории"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "loader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["загружаем..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
  "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["истекла"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "half_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полгода"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "notSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не выбрано"])},
  "goByLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["распечатать"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано!"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скопировать в буфер"])},
  "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата прошла успешно"])},
  "typeTextToFind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите текст для поиска"])},
  "sessionCloseByServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия закрыта. Необходимо пройти заново процедуру аутентификации."])},
  "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "alerts": {
    "sessionClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия закрыта"])},
    "sessionCloseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, так бывает. Чтобы продолжить, авторизуйтесь под своей учетной записью."])}
  },
  "pageTitles": {
    "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
    "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
    "Laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаборатории"])},
    "Laboratory": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Лаборатория ", _interpolate(_named("name"))])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписки"])},
    "SessionClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершение сессии"])},
    "Administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление"])},
    "AdministrationUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Список пользователей"])},
    "AdministrationUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Пользователь"])},
    "AdministrationLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Лицензии"])},
    "AdministrationRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Заявки"])},
    "AdministrationUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Создание пользователя"])},
    "AdministrationLimb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Лимб"])},
    "AdministrationOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Учреждение"])},
    "AdministrationOrgOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Учредитель"])},
    "AdministrationOrgUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Пользователи учреждения"])},
    "AdministrationOrgUsersUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Пользователь учреждения"])},
    "AdministrationOrgOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Заказы учреждения"])},
    "AdministrationOrgRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Заявки на вступление в учреждение"])},
    "AdministrationOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Заявка на вступление в учреждение"])},
    "AdministrationOrgLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Лицензии учреждения"])},
    "AdministrationOrgUserCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Создание пользователя учреждения"])},
    "AdministrationOrganizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Список учреждений"])},
    "AdministrationOrgCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ", "|", " Создание учреждения"])}
  },
  "labels": {
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
    "patronymic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почта"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык сайта и приложений"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
    "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Школа"])},
    "organization1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учреждение"])},
    "studyClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класс"])},
    "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название учебного учреждения"])},
    "userType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "localRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локальные роли"])},
    "requestedRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрашиваемая роль"])},
    "requestedClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрашиваемый класс"])},
    "fileAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепите файл"])},
    "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название группы"])},
    "addGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить группу"])},
    "notSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не указано"])},
    "classNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без класса"])},
    "groupNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без группы"])},
    "founderData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные учредителя"])}
  },
  "roles": {
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор портала"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модератор"])},
    "marketer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркетолог"])},
    "org_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец учреждения"])},
    "org_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модератор учреждения"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учитель"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ученик"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
  },
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно к заполнению."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле должно содержать минимум ", _interpolate(_named("min")), " символов."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поле должно содержать максимум ", _interpolate(_named("max")), " символов."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле должно быть действительным электронным адресом."])},
    "datepickerRangeOverflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком большая дата"])},
    "datepickerRangeUnderflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком маленькая дата"])},
    "datepickerTypeMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите дату в формате дд.мм.гггг"])},
    "valueMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение обязательно для заполнения"])},
    "incorrectGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Некорректное имя группы"])}
  },
  "filters": {
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класс"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "vizex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizex"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие"])},
    "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрано"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записей"])},
    "buttons": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триал"])}
    }
  },
  "menus": {
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаборатории"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписки"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензии"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказы"])},
    "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявки на вступление"])},
    "organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учреждения"])},
    "limb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимб"])},
    "ownersRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявки в учредители"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])}
  },
  "footer": {
    "publicOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичная оферта"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техподдержка"])},
    "systemRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системные требования"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
    "version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Версия: ", _interpolate(_named("version"))])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["© ООО «Визекс Инфо», 2013–", _interpolate(_named("date"))])}
  },
  "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензия"])},
  "licenses": {
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От учреждения"])}
  },
  "autoPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоплатеж"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активна"])},
  "packet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакет"])},
  "allInclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всё включено"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
  "issueTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выдать до"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать"])},
  "unban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокировать"])},
  "banUnban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировать / Разблокировать"])},
  "createNewOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать нового пользователя"])},
  "orgKick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить из учреждения"])},
  "leaveOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепление"])},
  "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте группу"])},
  "createModerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать модератора"])},
  "createOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать учреждение"])},
  "changeOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить учреждение"])},
  "deleteOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учреждение"])},
  "deleteUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Удаление пользователей"]), _normalize(["Удаление пользователя"]), _normalize(["Удаление учреждения"])])},
  "deleteUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Будьте внимательны, это действие необратимо, удаленных пользователей нельзя будет восстановить."]), _normalize(["Уверены, что хотите удалить пользователя?"]), _normalize(["Уверены, что хотите удалить учреждение?"])])},
  "banUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Блокировка пользователей"]), _normalize(["Блокировка пользователя"]), _normalize(["Блокировка учредителя"])])},
  "banUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Блокировка приведет к ограничению пользователю доступа к лабораториям, а если пользователь является учредителем, то он не сможет управлять своим учреждением."]), _normalize(["Блокировка приведет к ограничению пользователю доступа к лабораториям."])])},
  "banReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина блокировки"])},
  "unbanUserModalTitle": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Разблокировка пользователя"]), _normalize(["Разблокировка учредителя"])])},
  "unbanUserModalText": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Разблокировка приведет к восстановлению доступа пользователя к лабораториям."]), _normalize(["Разблокировка приведет к восстановлению доступа учредителя на управление учреждением."])])},
  "kickUserFromOrgModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключение из учреждения"])},
  "kickUserFromOrgModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключение приведет к потере доступа пользователя к выданным лицензиям."])},
  "usersTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя, Фамилия"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класс"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
    "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учреждение"])},
    "added_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлен"])},
    "registered_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ регистрации"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензии"])}
  },
  "orgsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
    "licenses_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензий"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учредитель"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлен"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "requestsTableColumns": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ Заявки"])},
    "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создана"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя, Фамилия"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Класс"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение"])},
    "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработал"])},
    "statusDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата решения"])}
  },
  "numerals": {
    "labs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("nn")), " лаборатория"]), _normalize([_interpolate(_named("nn")), " лаборатории"]), _normalize([_interpolate(_named("nn")), " лабораторий"])])},
    "students": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ученик"]), _normalize(["ученика"]), _normalize(["учеников"])])},
    "teachers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["учитель"]), _normalize(["учителя"]), _normalize(["учителей"])])},
    "users": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["пользователь"]), _normalize(["пользователя"]), _normalize(["пользователей"])])},
    "createUsers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["пользователя"]), _normalize(["пользователя"]), _normalize(["пользователей"])])},
    "moderators": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["модератор"]), _normalize(["модератора"]), _normalize(["модераторов"])])}
  },
  "emailNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почта ожидает подтверждения"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["что-то пошло не так..."])},
  "userCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь успешно создан"])},
  "userCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать еще одного"])},
  "emptyUserName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пользователь №", _interpolate(_named("id"))])},
  "nothingFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего не найдено"])},
  "createOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание Учреждения"])},
  "organizationCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учреждение успешно создано"])},
  "organizationCreateAnotherOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать еще одно"])},
  "laboratory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаборатория"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истекает"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автопродление"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триал"])},
  "youHaveNLaunchesLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["У вас остался ", _interpolate(_named("nn")), " запуск"]), _normalize(["У вас осталось ", _interpolate(_named("nn")), " запуска"]), _normalize(["У вас осталось ", _interpolate(_named("nn")), " запусков"])])},
  "usersListTableLeaveBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При переходе на страницу одного пользователя, выбор на этой станице будет утерян"])},
  "AdministrationOrdersConfirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение оплаты"])},
  "AdministrationOrdersConfirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвержденную оплату отменить нельзя"])},
  "AdminOrgOrdersToastOrderConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ оплачен!"])},
  "ToastOrderDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ удален!"])},
  "ToastSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранено!"])},
  "OrgShopToastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните все поля корректно"])},
  "ShopOrgTableAddNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["добавить лот"])},
  "ShopOrgTableAddNewProductError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните заказ перед тем как создать новый"])},
  "ShopSendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш заказ отправлен"])},
  "universalEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
  "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять"])},
  "declineRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить заявку"])},
  "acceptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить и принять заявку"])},
  "actionConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите выполняемое действие"])},
  "statuses": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принята"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонена"])}
  },
  "searchByContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по контексту"])},
  "searchByName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по названию"])},
  "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбрано"])},
  "notEnoughLicensesToDoThisAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["недостаточно лицензий для действия"])},
  "fileExtensionError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Файл \"", _interpolate(_named("name")), "\" имеет недопустимый формат (", _interpolate(_named("extension")), ")."])},
  "exportFromFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспортировать"])},
  "importFileWithError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать файл с ошибками"])},
  "exported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспортировано"])},
  "subscribePage": {
    "confirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для активации автоплатежа необходимо совершить покупку на 1руб для подтверждения карты."])},
    "emptyPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, у вас еще нет оплаченных подписок, зайдите в магазин, там найдется много интересного!"])},
    "packetText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вам доступны лаборатории:"])},
    "toShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В магазин"])}
  },
  "shop": {
    "invoiceName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["№ ", _interpolate(_named("num"))])},
    "textNotAuthorizedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы оформить подписку на лабораторию - создайте учетную запись и подтвердите почту"])},
    "autopaymentLabelAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["автоплатеж (можно отменить позже в личном кабинете)"])},
    "nextPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Следующий платеж ", _interpolate(_named("date")), ":"])},
    "nextPaymentSum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("sum")), " в месяц"]), _normalize([_interpolate(_named("sum")), " в полгода"]), _normalize([_interpolate(_named("sum")), " в год"])])},
    "buy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Купить выбранное"]), _normalize(["Купить"])])},
    "personalDataProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка персональных данных"])},
    "publicOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["публичной офертой"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["условиями использования"])},
    "personalDataProcessingRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политикой конфиденциальности"])},
    "personalDataProcessingInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Продолжая покупку, вы соглашаетесь с ", _interpolate(_list(0)), ", ", _interpolate(_list(1)), " и ", _interpolate(_list(2)), "."])},
    "periodPayment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["при оплате всех лабораторий на 1 месяц"]), _normalize(["при оплате всех лабораторий на 6 месяцев"]), _normalize(["при оплате всех лабораторий на 12 месяцев"])])},
    "tillDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["до ", _interpolate(_named("date"))])},
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить заказ"])},
    "sendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])},
    "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицензий"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать заказ"])},
    "orgEmptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы получить доступ к лабораториям и раздать лицензии вашим ученикам и учителям, оформите заказ и следуйте дальнейшим инструкциям."])},
    "orgEmptyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужен доступ для организации?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кол-во"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пусто"])},
    "closeShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данный момент магазин закрыт"])},
    "unavailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин временно недоступен"])},
    "unavailableText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По вопросам приобретения лицензий свяжитесь с нами:"])},
    "unavailableForUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уважаемые друзья!\nЛето это не только чудесное время года и школьные каникулы, лето – это еще время перемен!\nМы временно закрываем наш магазин, чтобы вернуться к вам с новой концепцией и новыми решениями для образования.\nИ, разумеется, все, у кого уже куплена подписка на наши виртуальные лаборатории, смогут продолжать ей пользоваться.\n⁠\nВсегда с вами, команда VR-Labs."])},
    "laboratories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаборатории"])},
    "ordersListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История заказов"])},
    "notPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не оплачен"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачен"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершен"])},
    "noStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не определен"])}
  },
  "administration": {
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пользователя"])},
    "classNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Б"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Г"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Д"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Е"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ж"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З"])}
    ],
    "org": {
      "actions": {
        "kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепить"])},
        "kickModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепление от учреждения"])},
        "kickModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте внимательны. Пользователи потеряют доступ к выданным им организацией лицензиям."])},
        "kickModalTextSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьте внимательны. Пользователь потеряет доступ к выданным ему организацией лицензиям."])},
        "waitingQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаем очереди..."])},
        "creatingUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создаем пользователей..."])},
        "usersCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи созданы..."])},
        "handleErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрабатываем ошибки..."])},
        "exportingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспортирование файла"])},
        "downloadFileWithErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать документ с ошибками"])},
        "brokenFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл не соответствует требованиям, попробуйте другой файл."])},
        "accountsCreated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Создано учетных записей: ", _interpolate(_named("created")), " из ", _interpolate(_named("count"))])},
        "accountsCreatedText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось создать ", _interpolate(_named("error")), ". Мы указали на ошибки в соответсвующих строках. Исправьте ошибки и загрузите документ еще раз."])}
      },
      "fileFormatError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["загружаемый документ не соответствует требуемому формату"])},
      "fileName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Файл от ", _interpolate(_named("date"))])},
      "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу пользователей (XLS)"])},
      "inviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для приглашения"])},
      "userRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка пользователя: "])},
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить группу"])},
      "changeClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить класс"])},
      "groupDeleteConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Чтобы удалить ", _interpolate(_list(0)), ", введите ее название в поле ниже."])},
      "noInviteLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данный момент у вас нет активной ссылки для вступления в ваше учебное учреждение"])},
      "infoTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Это кнопка открывает панель информации о вашем учебном учреждении."]), _normalize(["Здесь вы сможете создавать и редактировать группы, в которых будете объединять ваших учеников и учителей. Таким образом можно управлять филиалами или отделять модераторов от учеников."]), _normalize(["Чтобы поделиться ссылкой на вступление в вашу Организацию - нажмите кнопку справа"])])},
      "actionFileCreateTooltip": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Чтобы добавить много пользователей сразу, загрузите файл в формате «.xls». В содержании файла должны быть указаны ФИО, роль и E-mail пользователей. На указанные адреса пользователям будут присланы приглашения для регистрации со вступлением в учебное Учреждение."]), _normalize(["Если у ваших учеников нет e-mail адресов, то вы можете пригласить их присоединиться к учреждению с помощью QR-кода или ссылки-приглашения."])])},
      "downloadFileTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать .xls шаблон"])}
    },
    "limb": {
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрирован"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забанен"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
      "banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокирован"])}
    }
  },
  "profile": {
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль >"])},
    "waitingForConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание подтверждения"])},
    "myProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой профиль"])},
    "deleteModalMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Уверены, что хотите удалить свой аккаунт? Если у вас возникли какие-то проблемы с аккаунтом напишите нам на ", _interpolate(_list(0)), " и мы постараемся вам помочь."])},
    "subscribeMailing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться на рассылку"])},
    "deleteModalMessageOrg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Уверены, что хотите удалить свой аккаунт и учреждение? Если у вас возникли какие-то проблемы с аккаунтом напишите нам на ", _interpolate(_list(0)), " и мы постараемся вам помочь."])},
    "deleteModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление аккаунта"])},
    "deleteModalHeaderOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление учреждения"])},
    "restoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить аккаунт"])},
    "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить аккаунт"])},
    "deleteButtonOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить учреждение"])},
    "revokeOrgRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить заявку"])},
    "revokeOrgRequestText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Хотите отменить заявку на вступление в “", _interpolate(_named("org")), "”?"])},
    "leaveOrgButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепиться от школы >"])},
    "leaveOrgModalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепление от учреждения"])},
    "leaveOrgModalConfirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открепиться"])},
    "leaveOrgText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы покинете учреждение, вам более не будут доступны подписки, выданные вам этим учреждением."])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить заявку"])},
    "modalTitleRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пользователя"])},
    "modalTextRestoringUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите восстановить этого пользователя?"])},
    "attachToOrgText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Хотите отправить заявку на вступление в “", _interpolate(_named("org")), "”?"])},
    "disabledDeleteOrgTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя удалить учреждение, пока в нем есть кто-то, кроме учредителя"])},
    "accepted": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша заявка принята!"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы приняли вашу заявку на вступление в учебное учреждение. Увидеть статус заявки или отменить ее можно в вашем профиле."])}
    },
    "consists": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже состоите в этом учреждении"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поздравляем, ничего плохого не произошло. Вы просто пытались заново вступить в учреждение, в котором уже состоите"])}
    },
    "consists-another": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже состоите в учреждении"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, нельзя вступить в несколько учреждений одновременно. Если вы хотите сменить учреждение, сначала открепитесь от текущего"])}
    },
    "confirmation-await": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша заявка на рассмотрении"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы приняли ваше заявление о приеме в учебное заведение. Ваша заявка находится на рассмотрении"])}
    },
    "incorrect-role": {
      "applicationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К сожалению, вы не можете перейти по этой ссылке"])},
      "applicationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Измените роль своего профиля или попросите администратора изменить ее"])}
    }
  },
  "labsSystemRequirements": {
    "baseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системные требования"])},
    "labels": {
      "processor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процессор: "])},
      "RAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оперативная память: "])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеокарта: "])},
      "videoIntegrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "videoExternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение экрана: "])},
      "browsersSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемые браузеры: "])},
      "operationSystemsSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемые операционные системы: "])}
    },
    "descriptions": {
      "processor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intel серии Ivy Bridge / AMD серии Bulldozer и новее"])},
      "RAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 ГБ или больше"])},
      "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "videoIntegrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["встроенная: Intel HD Graphics 5000 / Intel Iris и новее"])},
      "videoExternal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дискретная: GeForce 400 серии или новее / Radeon HD 7000 серии или новее"])},
      "resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1920x1080 / 1920x1200 и больше (4K и Retina экраны требуют видеокарты большей мощности для комфортной работы)"])},
      "browsersSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chrome, Яндекс.Браузер, Opera 44+, Firefox 51+, Edge 79+, Safari 15+"])},
      "operationSystemsSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microsoft Windows 7 (х64 версии) или выше с поддержкой драйверов WebGL 2.0, MacOS X «Snow Leopard» 10.6 или выше с поддержкой драйверов WebGL 2.0, Linux или Debian с поддержкой драйверов WebGL 2.0"])}
    },
    "checkWebGL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать поддерживает ли именно ваше устройство WebGL 2.0 вы можете по ссылке - "])}
  },
  "price": {
    "currencyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUB"])},
    "currencySign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["₽"])},
    "monthPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / месяц"])},
    "halfYearPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / полгода"])},
    "yearPrice": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("price.currencySign", undefined, _type), " / год"])},
    "salePercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скидка, %"])}
  },
  "labs": {
    "section": {
      "labs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лаборатории"])},
      "teaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Методические пособия"])},
      "scientific_stands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Научные стенды"])},
      "tournaments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Турниры"])}
    },
    "licenseState": {
      "trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триал"])},
      "own": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
      "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От организации"])}
    },
    "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недавние"])},
    "emptySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увы, по вашему вопросу ничего не найдено. Может быть вы поищете что-нибудь в нашей библиотеке?"])},
    "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоро!"])},
    "fromPrice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["от ", _interpolate(_named("price"))])},
    "launch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запустить"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
    "workbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рабочая тетрадь"])},
    "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демо"])},
    "virtualLab": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Виртуальная лаборатория «", _interpolate(_named("name")), "»"])},
    "subscriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформите подписку прямо сейчас и получите доступ к лаборатории!"])},
    "oneRubSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оформите подписку прямо сейчас и получите доступ к лаборатории!"])},
    "clearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятно"])},
    "mobileModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На мобильных устройствах приложение пока недоступно."])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ой..."])}
  }
}