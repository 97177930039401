import { Dayjs } from 'dayjs';
import { computed, ComputedRef, Ref } from 'vue';
import { useI18n, VueI18n } from 'vue-i18n';

import dayjs from '@/utils/dayjs';

import { LicenseItem, SelectOption } from '@/types';
import { SubscribeUser, SubscribeOrg } from '@/router/Subscriptions/types';

interface BadgeProps {
  color: 'success' | 'warning' | 'danger';
  appearance: 'outline' | 'solid';
}

type GearColor = 'success' | 'warning' | 'danger';

type UseUserLicenses = {
  options: Array<SelectOption>;
  expireText: ComputedRef<string>;
  badgeProps: ComputedRef<BadgeProps>;
  gearColor: ComputedRef<GearColor>;
  expires: ComputedRef<Dayjs | null>;
};

export const optionsConstructor = (t: VueI18n['t']): Array<SelectOption> => [
  {
    value: 1,
    label: t('month'),
  },
  {
    value: 12,
    label: t('year'),
  },
];

export const useUserLicenses = (
  license: Ref<SubscribeUser | SubscribeOrg | LicenseItem>
): UseUserLicenses => {
  const { t } = useI18n();
  const now = dayjs();
  const options = optionsConstructor(t);
  const expires = computed(
    () => (license.value?.date_expiration
      ? dayjs(license.value.date_expiration)
      : null)
  );
  const diff = computed(
    () => (expires.value
      ? expires.value.diff(now, 'day')
      : 0)
  );

  const badgeProps = computed(() => {
    const prop: BadgeProps = {
      color: 'danger',
      appearance: 'outline',
    };
    if (license.value.status && expires.value) {
      prop.appearance = diff.value < 0 ? 'outline' : 'solid';
      if (diff.value >= 7) {
        prop.color = 'success';
      } else if (diff.value >= 3) {
        prop.color = 'warning';
      } else {
        prop.color = 'danger';
      }
    }
    return prop;
  });

  const gearColor = computed(() => {
    if (license.value.status && expires.value) {
      if (diff.value >= 7) {
        return 'success';
      }

      if (diff.value >= 3) {
        return 'warning';
      }
    }
    return 'danger';
  });

  const expireText = computed(() => {
    if (!license.value.status && diff.value < 0) {
      return `${t('expired')} `;
    }
    return '';
  });

  return {
    options,
    expireText,
    badgeProps,
    gearColor,
    expires,
  };
};
