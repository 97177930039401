<template>
  <router-link
    :to="{ name: names.laboratory, params: { id: card.id } }"
    class="card"
  >
    <picture class="card__picture">
      <img
        :src="fallbackSrc"
        :srcset="fallbackSrcset"
        :alt="card.name"
        class="card__img"
      />
    </picture>
    <div class="card__front">
      <h3 class="card__title">
        {{ card.subsection.name }}
      </h3>
      <p class="card__text">
        {{ card.name }}
      </p>
    </div>
    <div
      v-if="card.has_workbook"
      class="card__workbook"
    >
      <IconWorkbook />
    </div>
    <img
      class="card__play"
      src="/img/icons/play.svg"
      alt="play"
    />
  </router-link>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue';
import { names } from '@/constants/routes';
import { imageCandidatesToSrcset } from '@/utils';

import IconWorkbook from '@/components/icons/Workbook.vue';

import { LabData } from '../types';

const props = defineProps<{
  card: LabData;
}>();

const appImg = toRef(() => props.card.app_img);

const fallbackSrc = computed(() => appImg.value['240x160']);

const fallbackSrcset = computed(() => imageCandidatesToSrcset({
  '1x': appImg.value['240x160'],
  '2x': appImg.value['480x320'],
}));
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.card {
  display: block;
  border-radius: 14px;
  border: 3px solid $color-white;
  position: relative;
  max-width: 240px;
  width: 100%;
  overflow: hidden;
  transition: border-color $transition-speed;

  @include hover-focus() {
    border-color: $color-cyan;

    .card__play {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.card__picture {
  height: 100%;
}

.card__img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.card__front {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.card__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s;
}

.card__title {
  width: fit-content;

  font-size: 16px;
  color: $color-white;
  padding: 4px 8px;
  background: var(--color-blue-blueberry);
  border-radius: 10px;
}

.card__text {
  width: fit-content;
  font-size: 14px;
  padding: 4px 8px 4px 16px;
  color: $color-white;
  background: var(--color-blue-blueberry);
  box-shadow: $box-shadow-white;
}

.card__workbook {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  border-bottom-left-radius: 12px;
  background: $color-peach;
  font-size: 34px;
}
</style>
