import { roles, suRoles, orgAccessRoles, allRoles } from './roles';

export const names = {
  profile: 'Profile',
  price: 'Price',
  shop: 'Shop',
  invoices: 'Invoices',
  laboratories: 'Laboratories',
  laboratory: 'Laboratory',
  subscribe: 'Subscriptions',
  alerts: {
    closed: 'SessionClosed',
  },
  errorsMock: 'ErrorsMock',
  administration: {
    root: 'Administration',
    users: 'AdministrationUsers',
    user: 'AdministrationUsersUser',
    licenses: 'AdministrationLicenses',
    requests: 'AdministrationRequests',
    createUser: 'AdministrationUserCreate',
    limb: 'AdministrationLimb',
    organization: {
      root: 'AdministrationOrg',
      owner: 'AdministrationOrgOwner',
      users: 'AdministrationOrgUsers',
      user: 'AdministrationOrgUsersUser',
      orders: 'AdministrationOrgOrders',
      requests: 'AdministrationOrgRequests',
      request: 'AdministrationOrgRequest',
      licenses: 'AdministrationOrgLicenses',
      createUser: 'AdministrationOrgUserCreate',
    },
    organizations: 'AdministrationOrganizations',
    createOrg: 'AdministrationOrgCreate',
  },
};

export const paths = {
  profile: '/profile/:type?',
  price: '/price',
  shop: '/shop',
  invoices: '/invoices',
  laboratories: '/',
  laboratory: '/laboratory/:id',
  subscribe: '/subscriptions',
  alerts: {
    closed: '/session-closed',
  },
  errorsMock: '/errors-mock',
  administration: {
    root: '/administration',
    users: '/administration/users',
    user: '/administration/users/user/:id/:tab?',
    licenses: '/administration/licenses',
    requests: '/administration/requests',
    createUser: '/administration/users/create',
    limb: '/administration/limb',
    organization: {
      root: '/administration/org/:orgId',
      owner: '/administration/org/:orgId/owner',
      users: '/administration/org/:orgId/users',
      user: '/administration/org/:orgId/user/:id',
      orders: '/administration/org/:orgId/orders',
      requests: '/administration/org/:orgId/requests',
      request: '/administration/org/:orgId/requests/:id',
      licenses: '/administration/org/:orgId/licenses',
      createUser: '/administration/org/:orgId/users/create',
    },
    organizations: '/administration/organizations',
    createOrg: '/administration/organizations/create',
  },
};

export const permissions = {
  profile: [
    roles.admin,
    roles.moderator,
    roles.marketer,
    roles.student,
    roles.teacher,
    roles.orgOwner,
    roles.orgModerator,
  ],
  laboratories: allRoles,
  laboratory: allRoles,
  alerts: allRoles,
  price: [...suRoles, roles.marketer],
  subscribe: [roles.student, roles.teacher, roles.marketer],
  shop: [
    roles.marketer,
    roles.student,
    roles.teacher,
    roles.guest,
  ],
  invoices: [
    roles.orgOwner,
    roles.orgModerator,
  ],
  administration: {
    root: orgAccessRoles,
    users: suRoles,
    user: suRoles,
    licenses: [],
    requests: [],
    createUser: suRoles,
    limb: suRoles,

    organization: {
      orders: suRoles,
      owner: suRoles,

      root: orgAccessRoles,
      users: orgAccessRoles,
      user: orgAccessRoles,
      requests: orgAccessRoles,
      request: orgAccessRoles,
      licenses: orgAccessRoles,
      createUser: orgAccessRoles,
    },
    organizations: suRoles,
    createOrg: suRoles,
  },
};

export default {
  names,
  paths,
  permissions,
};
