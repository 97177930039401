import { roles } from '@/constants/roles';

export const UserData = {
  state() {
    return {
      user: null,
      token: null,
    };
  },
  getters: {
    isAuthed: (state) => {
      if (!state.user) {
        return false;
      }
      return Object.keys(state.user).length > 0;
    },
    lang: (state) => state.user?.lang || null,
    role: (state) => state.user?.role?.shortname || roles.guest,
    roleOption: (state) => [
      { value: state.user?.role?.id, label: state.user?.role?.name },
    ],
    isOrgAttached: (state) => Boolean(state.user?.organization),
    roleId: (state) => state.user?.role?.id || 10,
    userId: (state) => state.user?.id || null,
  },
  actions: {
    clearUserData({ commit }) {
      commit('SET_USER', null);
    },
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
  },
};

export default {
  UserData,
};
