import { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { names, paths, permissions } from '@/constants/routes';

const user: Array<RouteRecordRaw> = [
  {
    name: names.administration.organization.user,
    path: paths.administration.organization.user,
    meta: {
      permissions: permissions.administration.organization.user,
      isAuthed: false,
      requiresAuth: true,
    },
    component: () => import(
      /* webpackPrefetch: true */
      /* webpackChunkName: 'administration-org' */
      './index.vue'
    ),
    beforeEnter(to, from, next) {
      if (!to?.params?.id) {
        next(from || { name: names.administration.root });
      } else if (Number(to.params.id) === store.getters.userId) {
        // Redirect into profile page if try open yourself page
        next({ name: names.profile });
      } else {
        next();
      }
    },
  },
];

export default user;
