/* eslint-disable line-comment-position */
const roleAdmin = 'admin'; // 1
const roleModerator = 'moderator'; // 2
const roleMarketer = 'marketer'; // 4
const roleOrgOwner = 'org_owner'; // 5
const roleOrgModerator = 'org_moderator'; // 6
const roleTeacher = 'teacher'; // 7
const roleStudent = 'student'; // 8
const roleGuest = 'guest'; // undefined

export const roles = {
  admin: roleAdmin,
  moderator: roleModerator,
  marketer: roleMarketer,
  orgOwner: roleOrgOwner,
  orgModerator: roleOrgModerator,
  teacher: roleTeacher,
  student: roleStudent,
  guest: roleGuest,
};

export const suRoles = [roles.admin, roles.moderator];

export const orgRoles = [roles.orgOwner, roles.orgModerator];

export const usualRoles = [roles.teacher, roles.student, roles.guest];

export const orgAccessRoles = [...suRoles, ...orgRoles];

export const allRoles = [...orgAccessRoles, ...usualRoles, roles.marketer];

export default {
  roles,
  suRoles,
  orgRoles,
  orgAccessRoles,
  allRoles,
};
