export const isMobile = (): boolean => {
  if (
    typeof window === 'undefined'
    || !window.navigator
    || !window.navigator.userAgent
  ) {
    return true;
  }

  if (navigator.userAgent.match(/Android/i)) {
    return true;
  }
  if (navigator.userAgent.match(/UC|(Opera mini)/i)) {
    return true;
  }
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return true;
  }
  if (navigator.userAgent.match(/BlackBerry/i)) {
    return true;
  }
  if (navigator.userAgent.match(/Windows (Phone|CE)/i)) {
    return true;
  }
  if (navigator.userAgent.match(/Lumia|Nokia/i)) {
    return true;
  }
  if (navigator.userAgent.match(/Mobile|Tablet/i)) {
    return true;
  }

  return false;
};

export default isMobile();
